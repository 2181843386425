var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: _vm.backCss },
    [
      _c(
        "div",
        { staticClass: "statistics-menu-list" },
        [
          _vm._l(_vm.copyColumns, function (item, index) {
            return [
              _c(
                "a-dropdown",
                {
                  key: index,
                  ref: "statistics-" + index,
                  refInFor: true,
                  style: item.isNum ? "" : "display: none;",
                  attrs: { trigger: ["click"], placement: "topCenter" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "statistics" },
                    [
                      _vm._v("统计"),
                      _c("a-icon", { attrs: { type: "caret-up" } }),
                    ],
                    1
                  ),
                  _c(
                    "a-menu",
                    { attrs: { slot: "overlay" }, slot: "overlay" },
                    [
                      item.isNum
                        ? _c(
                            "a-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.compute(
                                    "meanNum",
                                    item.dataIndex,
                                    index,
                                    "求和"
                                  )
                                },
                              },
                            },
                            [_vm._v("\n            求和\n          ")]
                          )
                        : _vm._e(),
                      item.isNum
                        ? _c(
                            "a-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.compute(
                                    "sumNum",
                                    item.dataIndex,
                                    index,
                                    "平均"
                                  )
                                },
                              },
                            },
                            [_vm._v("\n            平均\n          ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
      _c(
        "a-spin",
        { attrs: { spinning: _vm.tableLoading } },
        [
          _c(
            "a-dropdown",
            {
              attrs: { placement: "bottomRight", trigger: ["contextmenu"] },
              model: {
                value: _vm.visible,
                callback: function ($$v) {
                  _vm.visible = $$v
                },
                expression: "visible",
              },
            },
            [
              _c(
                "a-menu",
                { attrs: { slot: "overlay" }, slot: "overlay" },
                _vm._l(_vm.columns, function (item) {
                  return _c(
                    "a-menu-item",
                    { key: item.dataIndex },
                    [
                      _c(
                        "a-checkbox",
                        {
                          on: {
                            change: function ($event) {
                              return _vm.checkChange(item)
                            },
                          },
                          model: {
                            value: item.checked,
                            callback: function ($$v) {
                              _vm.$set(item, "checked", $$v)
                            },
                            expression: "item.checked",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.title) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "vxe-table",
                {
                  ref: "xTree",
                  attrs: {
                    border: "none",
                    "row-config": {
                      isCurrent: true,
                      isHover: true,
                      keyField: "id",
                    },
                    "tree-config": _vm.treeConfig,
                    "show-overflow": "",
                    "footer-method": _vm.footerMethod,
                    data: _vm.tableData,
                    "sort-config": {
                      orders: ["asc", "desc"],
                      trigger: "cell",
                      iconAsc: "iconfont icon-32pxshengxu icon",
                      iconDesc: "iconfont icon-32pxjiangxu icon",
                    },
                    "filter-config": {
                      remote: true,
                      iconNone: "iconfont icon-shaixuan icon",
                      iconMatch:
                        "iconfont icon-shaixuan icon filter-product-btn",
                    },
                    "show-footer": "",
                  },
                  on: {
                    "toggle-tree-expand": _vm.toggleExpandChangeEvent,
                    "checkbox-change": _vm.selectChangeEvent,
                    "checkbox-all": _vm.selectChangeEvent,
                    "cell-click": _vm.cellClickEvent,
                    scroll: _vm.tableScroll,
                    "sort-change": _vm.sortChangeEvent,
                    "filter-change": _vm.filterChangeEvent,
                  },
                },
                [
                  _c("vxe-column", {
                    attrs: {
                      align: "center",
                      fixed: "left",
                      type: "checkbox",
                      minWidth: "80",
                      "show-header-overflow": "",
                    },
                  }),
                  _c("vxe-column", {
                    attrs: {
                      field: "time",
                      fixed: "left",
                      title: "日期",
                      minWidth: 160,
                      "tree-node": true,
                    },
                  }),
                  _c("vxe-column", {
                    attrs: {
                      field: "type",
                      sortable: "",
                      title: "签约类型",
                      filters: [
                        { label: "全职", value: "全职" },
                        { label: "兼职", value: "兼职" },
                        { label: "意向全职", value: "意向全职" },
                        { label: "实习", value: "实习" },
                      ],
                      minWidth: 120,
                    },
                  }),
                  _c("vxe-column", {
                    attrs: { field: "position", title: "对象", minWidth: 100 },
                  }),
                  _c("vxe-column", {
                    attrs: {
                      field: "positionName",
                      title: "职位",
                      minWidth: 180,
                    },
                  }),
                  _c(
                    "vxe-colgroup",
                    { attrs: { title: "考核项目" } },
                    [
                      _c("vxe-column", {
                        attrs: {
                          title: "考核数量",
                          sortable: "",
                          field: "count",
                          minWidth: 100,
                        },
                      }),
                      _c("vxe-column", {
                        attrs: {
                          title: "平均错翻数",
                          sortable: "",
                          field: "error",
                          minWidth: 100,
                          "show-header-overflow": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                !row.hasChild
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(row.count ? row.error : "/")
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("vxe-column", {
                        attrs: {
                          title: "平均硬伤数",
                          sortable: "",
                          field: "hard",
                          minWidth: 100,
                          "show-header-overflow": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                !row.hasChild
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(row.count ? row.hard : "/")
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("vxe-column", {
                        attrs: {
                          title: "平均软伤数",
                          sortable: "",
                          field: "soft",
                          minWidth: 100,
                          "show-header-overflow": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                !row.hasChild
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(row.count ? row.soft : "/")
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("vxe-column", {
                        attrs: {
                          title: "平均修改率",
                          sortable: "",
                          field: "modifyRatio",
                          minWidth: 100,
                          "show-header-overflow": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                !row.hasChild
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          row.count
                                            ? row.modifyRatio &&
                                                row.modifyRatio + "%"
                                            : "/"
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("vxe-column", {
                        attrs: {
                          title: "平均得分",
                          sortable: "",
                          field: "score",
                          minWidth: 100,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                !row.hasChild
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(row.count ? row.score : "/")
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "vxe-colgroup",
                    { attrs: { title: "实际完成情况数量" } },
                    [
                      _c("vxe-column", {
                        attrs: {
                          title: "总数",
                          field: "total",
                          sortable: "",
                          minWidth: 100,
                        },
                      }),
                      _c("vxe-column", {
                        attrs: {
                          title: "提前",
                          field: "advance",
                          sortable: "",
                          minWidth: 100,
                        },
                      }),
                      _c("vxe-column", {
                        attrs: {
                          title: "按时",
                          field: "onTime",
                          sortable: "",
                          minWidth: 100,
                        },
                      }),
                      _c("vxe-column", {
                        attrs: {
                          title: "拖稿",
                          field: "delay",
                          sortable: "",
                          minWidth: 100,
                        },
                      }),
                      _c("vxe-column", {
                        attrs: {
                          title: "拖稿率",
                          field: "delayNum",
                          sortable: "",
                          minWidth: 100,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                !row.hasChild
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          Math.round(
                                            (row.delay / row.total) * 100
                                          ) + "%"
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }